<template>
  <b-overlay :show="loading" class="h-100">
    <div class="w-100 h-100">
      <div>
        <div class="p-4 wrapper__week">
          <div class="week__list">
            <b-button
              v-for="(item, index) in weeks"
              :key="index"
              class="btn btn-outline-success mr-3"
              :class="{ 'btn-success': weekFocus === item.week }"
              type="button"
              @click="changeWeek(item)"
            >
              {{ item.weekTitle }}
            </b-button>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <basic-date-picker
              v-model="date"
              type="date"
              format="DD/MM/YYYY"
              value-type="format"
              class="date__picker"
              @change="selectDate"
            />
            <b-button
              class="btn btn-outline-success ml-3"
              type="button"
              @click="setNowDate"
            >
              Hôm nay
            </b-button>
          </div>
        </div>
        <div
          v-if="isWriteAllPermission || isWriteOwnerPermission"
          class="py-4 d-flex justify-content-end"
        >
          <b-button
            class="btn btn-success ml-3"
            type="button"
            @click="addActivity"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
            </span>
            Thêm mới hoạt động
          </b-button>
          <b-button
            class="btn btn-success ml-3"
            type="button"
            @click="openModalSchedule"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
            </span>
            Thêm mới lộ trình
          </b-button>
        </div>
        <HorizontalCalendar
          :date="date"
          :days="daysInCurrentWeek"
          :showPrevBtn="showPrevBtn"
          :showNextBtn="showNextBtn"
          @change="handleChangeWeek"
          @date-change="handleChangeDate"
        />

        <div class="p-4">
          <b-row>
            <b-col>
              <h6>Mục tiêu ngày</h6>
              <p class="text-gray mb-4">
                Hoàn thành: {{ totalTargetCompletedDaily }}/{{
                  totalTargetDaily
                }}
              </p>
              <div v-if="dailyList.length" class="goal__list">
                <div
                  v-for="(target, idx) in dailyList"
                  :key="idx"
                  class="goal__item"
                >
                  <span
                    class="svg-icon-target"
                    :class="{
                      'icon-weight': target.type === TARGET_TYPES.WEIGHT,
                    }"
                  >
                    <inline-svg :src="getIconByTargetType(target.type)" />
                  </span>
                  <span class="font-weight-bolder">{{
                    getActivityName(target)
                  }}</span>
                  <div class="goal__infomation">
                    <span class="font-weight-bold">{{
                      getActivityDescription(target)
                    }}</span>
                    <div class="wrap__actions">
                      <span
                        v-if="target.state === TARGET_STATUS.COMPLETED"
                        class="svg-icon text-success goal__action"
                      >
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/check.svg"
                        />
                      </span>
                      <span v-if="showStudyProgress(target)">{{
                        getPercent(target)
                      }}</span>
                      <span
                        v-if="!checkHidenActionDelete(target.type)"
                        class="svg-icon text-danger goal__action cursor-pointer"
                        @click="showPopupConfirmDelete(target)"
                      >
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/trash-2.svg"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col>
              <h6>Mục tiêu tuần</h6>
              <p class="text-gray mb-4">
                Hoàn thành: {{ totalTargetCompletedWeekly }}/{{
                  totalTargetWeekly
                }}
              </p>
              <div v-if="weeklyList.length" class="goal__list">
                <div
                  v-for="(target, idx) in weeklyList"
                  :key="idx"
                  class="goal__item"
                >
                  <span class="svg-icon-target">
                    <inline-svg :src="getIconByTargetType(target.type)" />
                  </span>
                  <span class="font-weight-bolder">{{
                    getActivityName(target)
                  }}</span>
                  <div class="goal__infomation">
                    <span class="font-weight-bold">{{
                      getActivityDescription(target)
                    }}</span>
                    <div>
                      <span
                        v-if="target.state === TARGET_STATUS.COMPLETED"
                        class="svg-icon text-success goal__action"
                      >
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/check.svg"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <ModalAddSchedule @reset="resetModalAddSchedule" />
        <ActivityModalForm @reset="getTargetList" />
      </div>
    </div>
  </b-overlay>
</template>
<script>
import round from 'lodash/round';
import { createNamespacedHelpers } from 'vuex';
import {
  TARGET_STATUS,
  TARGET_TYPES,
} from '../../../../core/plugins/constants';
const { mapState } = createNamespacedHelpers('userDashboard');
export default {
  name: 'Schedule',
  components: {
    HorizontalCalendar: () => import('../components/HorizontalCalendar.vue'),
    ModalAddSchedule: () => import('../components/ModalAddSchedule.vue'),
    ActivityModalForm: () => import('../components/ActivityModalForm.vue'),
  },
  data() {
    return {
      showModalAddSchedule: false,
      date: this.$moment(new Date()).format('DD/MM/YYYY'),
      currentDate: this.convertDateToTimestamp(
        this.$moment(new Date()).format('DD/MM/YYYY'),
      ),
      weeks: [],
      daysInCurrentWeek: [],
      loading: false,
      weekFocus: 0,
      TARGET_STATUS,
      dailyList: [],
      weeklyList: [],
      totalTargetDaily: 0,
      totalTargetCompletedDaily: 0,
      totalTargetWeekly: 0,
      totalTargetCompletedWeekly: 0,
      TARGET_TYPES,
      minximumWeek: -8,
      maximumWeek: 4,
    };
  },
  computed: {
    ...mapState(['customerInfo']),

    searchParams() {
      return {
        patientId: this.patientId,
        week: this.weekFocus,
        day: this.convertDateToTimestamp(this.date),
      };
    },
    patientId() {
      return this.$route.params.id;
    },
    isHasRoadMap() {
      return this.customerInfo?.ownPackage?.ownRoadmap || false;
    },
    showPrevBtn() {
      if (this.isHasRoadMap) {
        return this.weekFocus !== 0;
      }

      return this.weekFocus > this.minximumWeek;
    },
    showNextBtn() {
      if (this.isHasRoadMap) {
        return this.weekFocus !== this.weeks.length - 1;
      }

      return this.weekFocus < this.maximumWeek;
    },
    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWriteOwnerPermission() {
      return this.$route.meta.isWriteOwnerPermission;
    },
  },
  watch: {
    customerInfo: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      if (!this.customerInfo) return;
      this.loading = true;
      await this.searchWeekByDate();
      await Promise.all([this.getTargetWeek(false), this.getTargetList(false)]);
      this.loading = false;
    },
    async getTargetWeek(isLoading = true) {
      try {
        isLoading && (this.loading = true);
        const { meta, data } = await this.$api.get(
          '/UserDashboard/Target-Week',
          { params: this.searchParams },
        );

        if (!meta.success) return;

        this.weeks = data.weeks;
        this.daysInCurrentWeek = data.daysInCurrentWeek;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        isLoading && (this.loading = false);
      }
    },
    async searchWeekByDate() {
      try {
        const { meta, data } = await this.$api.get(
          '/UserDashboard/Get-Week-By-Date',
          {
            params: {
              date: this.convertDateToTimestamp(this.date),
              activationDate: this.isHasRoadMap
                ? this.customerInfo.ownPackage?.activationDate
                : this.convertDateToTimestamp(this.date),
              duration: this.customerInfo.ownPackage?.duration,
              patientId: this.patientId,
            },
          },
        );

        if (!meta.success) return;

        this.weekFocus = data;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      }
    },
    async getTargetList(isLoading = true) {
      try {
        isLoading && (this.loading = true);
        const { meta, data } = await this.$api.get('/UserDashboard/Target', {
          params: {
            day: this.convertDateToTimestamp(this.date),
            patientId: this.patientId,
            week: this.weekFocus,
          },
        });

        if (!meta.success) return;

        this.dailyList = data.daily;
        this.weeklyList = data.weekly;
        this.totalTargetDaily = data.totalTargetDaily;
        this.totalTargetCompletedDaily = data.totalTargetCompletedDaily;
        this.totalTargetWeekly = data.totalTargetWeekly;
        this.totalTargetCompletedWeekly = data.totalTargetCompletedWeekly;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        isLoading && (this.loading = false);
      }
    },
    openModalSchedule() {
      this.$bvModal.show('modal-add-schedule');
    },
    resetModalAddSchedule() {},
    addActivity() {
      this.$bvModal.show('modal-activity-form');
    },
    setNowDate() {
      this.date = this.$moment(new Date()).format('DD/MM/YYYY');
      this.loadData();
    },
    selectDate(date) {
      if (date) {
        this.date = date;
      } else {
        this.date = this.$moment(new Date()).format('DD/MM/YYYY');
      }
      this.loadData();
    },
    async changeWeek(item) {
      this.loading = true;
      this.weekFocus = item.week;
      await this.getTargetWeek(false);
      this.date = this.convertTimestampToDate(this.daysInCurrentWeek[0].day);
      await this.getTargetList(false);
      this.loading = false;
    },
    async handleChangeWeek({ type }) {
      this.loading = true;
      if (type === 'prev') {
        this.weekFocus = this.weekFocus - 1;
      } else {
        this.weekFocus = this.weekFocus + 1;
      }
      await this.getTargetWeek(false);
      this.date = this.convertTimestampToDate(this.daysInCurrentWeek[0].day);
      await this.getTargetList(false);
      this.loading = false;
    },
    handleChangeDate(item) {
      this.date = this.convertTimestampToDate(item.day);
      this.getTargetList();
    },
    checkHidenActionDelete(state) {
      const dateTimestamp = this.convertDateToTimestamp(this.date);
      return (
        dateTimestamp < this.currentDate ||
        [
          TARGET_TYPES.EXERCISE_MOVEMENT,
          TARGET_TYPES.BOOK_1_1,
          TARGET_TYPES.BOOK_1_n,
          TARGET_TYPES.INPUT_RATING,
          TARGET_TYPES.OUTPUT_RATING,
          TARGET_TYPES.UPDATE_PROFILE,
        ].includes(state)
      );
    },
    getIconByTargetType(type) {
      switch (type) {
        case TARGET_TYPES.EXERCISE:
          return '/media/user-dashboard/exercise.svg';
        case TARGET_TYPES.EXERCISE_MOVEMENT:
          return '/media/user-dashboard/exercise-movement.svg';
        case TARGET_TYPES.BOOK_1_1:
          return '/media/user-dashboard/coach.svg';
        case TARGET_TYPES.BOOK_1_n:
          return '/media/user-dashboard/coach-1N.svg';
        case TARGET_TYPES.INPUT_RATING:
        case TARGET_TYPES.OUTPUT_RATING:
          return '/media/user-dashboard/evaluation.svg';
        case TARGET_TYPES.BLOOD_PRESSURE:
          return '/media/user-dashboard/blood_pressure.svg';
        case TARGET_TYPES.BLOOD_SUGAR:
          return '/media/user-dashboard/blood_sugar.svg';
        case TARGET_TYPES.WEIGHT:
          return '/media/user-dashboard/weight.svg';
        case TARGET_TYPES.EMOTION:
          return '/media/user-dashboard/emotion.svg';
        case TARGET_TYPES.FOOD:
          return '/media/user-dashboard/food.svg';
        case TARGET_TYPES.LESSON:
          return '/media/user-dashboard/lesson.svg';
        case TARGET_TYPES.SURVEY:
          return '/media/user-dashboard/survey.svg';

        default:
          return '/media/user-dashboard/target.svg';
      }
    },
    getActivityName(target) {
      switch (target.type) {
        case TARGET_TYPES.LESSON:
          return 'Bài học';
        case TARGET_TYPES.SURVEY:
          return 'Khảo sát';
        case TARGET_TYPES.EXERCISE:
          return 'Vận động';
        case TARGET_TYPES.UPDATE_PROFILE:
          return 'Cập nhật hồ sơ';
        case TARGET_TYPES.BLOOD_SUGAR:
          return 'Đo đường huyết';
        case TARGET_TYPES.WEIGHT:
          return 'Đo cân nặng';
        case TARGET_TYPES.EMOTION:
          return 'Cảm xúc';
        case TARGET_TYPES.FOOD:
          return 'Nhập món ăn';
        case TARGET_TYPES.BLOOD_PRESSURE:
          return 'Đo huyết áp';

        default:
          return target.name;
      }
    },
    getActivityDescription(target) {
      switch (target.type) {
        case TARGET_TYPES.BOOK_1_1:
        case TARGET_TYPES.BOOK_1_n:
        case TARGET_TYPES.INPUT_RATING:
        case TARGET_TYPES.OUTPUT_RATING:
          return 'Hoạt động theo lộ trình';
        case TARGET_TYPES.FREE:
          if (target.executeType) {
            return `${target.executeDayTimes} lần`;
          }
          return `${target.executeDayTimes} phút`;
        default:
          return target.description;
      }
    },
    showPopupConfirmDelete(target) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn xóa ${this.getActivityName(
          target,
        )} không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            this.deleteTarget(target.id);
          }
        }.bind(this),
      );
    },
    async deleteTarget(id) {
      try {
        this.loading = true;
        const { meta, error } = await this.$api.delete(
          `/UserDashboard/Target/${id}`,
        );

        if (!meta.success) {
          return this.showToastrMessage(error.message);
        }

        this.showToastrMessage('Xóa thành công', 'succes');
        await this.getTargetList(false);
      } catch (error) {
        this.showToastrMessage(error.message);
      } finally {
        this.loading = false;
      }
    },
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
    showStudyProgress(target) {
      if (target.state !== TARGET_STATUS.IN_PROGRESS) return false;
      return [
        TARGET_TYPES.BLOOD_SUGAR,
        TARGET_TYPES.WEIGHT,
        TARGET_TYPES.EMOTION,
        TARGET_TYPES.FOOD,
        TARGET_TYPES.BLOOD_PRESSURE,
        TARGET_TYPES.EXERCISE,
      ].includes(target.type);
    },
    getPercent(target) {
      if (!target.executeDayTimes) return null;
      const percent =
        (target.actualExecuteDayTimes / target.executeDayTimes) * 100;
      return `${round(percent)}%`;
    },
  },
};
</script>
<style lang="scss">
.date__picker .form-group {
  margin-bottom: 0;
}

.icon-weight svg g path {
  fill: #4bb2ab !important;
}
</style>

<style lang="scss" scoped>
.goal {
  &__item {
    padding: 8px 20px;
    background: #def2f1;
    border-radius: 8px;
    display: grid;
    grid-template-columns: max-content 1fr 1.25fr;
    column-gap: 30px;
    align-items: center;
  }
  &__list {
    display: grid;
    row-gap: 16px;
  }

  &__infomation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
  }

  &__action {
    margin-right: 12px;

    svg {
      width: 27px;
      height: 27px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.wrapper__week {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 24px;

  @media only screen and (max-width: 1440px) {
    grid-template-columns: 2fr 1.5fr;
    column-gap: 12px;
  }
  @media only screen and (max-width: 1366px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
  }
}

.week__list {
  overflow: auto;
  white-space: nowrap;
  padding: 8px 0;
}
.svg-icon-target svg {
  width: 40px;
  height: 40px;
}

.wrap__actions {
  display: flex;
  justify-content: space-between;
  column-gap: 12px;
  align-items: center;
}
</style>
